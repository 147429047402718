<script setup>
import Star from '@/Components/Icons/Star.vue';
import {computed} from 'vue';

const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    score: {
        type: Number,
        required: true,
    },
})

const fullStars = computed(() => {
    return Math.floor(props.score)
})

const partialStar = computed(() => {
    return props.score - fullStars.value
})

const overlayWidth = (index) => {
    if (index === fullStars.value + 1) {
        return 15 * (1 - partialStar.value)
    }

    return 0;
}
</script>

<template>
    <div class="col-span-1">
        <h3 class="flex text-sm text-gray-400">{{ title }}:</h3>
        <span class="text-xl font-semibold">{{ score }}</span>

        <div class="flex items-center gap-[2px]">
            <div v-for="index in 5" :key="index" class="relative">
                <Star v-if="score + 1 >= index"/>
                <div v-if="index > fullStars" :style="{ width: overlayWidth(index) + 'px' }" class="absolute top-0 right-0 bottom-0 bg-white"></div>
            </div>
        </div>
    </div>
</template>
